import React from "react";

export default function ToggleDarkMode({ styleMode, setStyleMode }) {
  return (
    <>
      <div className="darkmode-switch">
        <label className="darkmode-switch label">
          <input
            type="checkbox"
            className="darkmode-switch checkbox"
            onClick={setStyleMode}
          />
          <span className="darkmode-switch slider"></span>
        </label>
      </div>
    </>
  );
}

/*
<button onClick={setStyleMode}>
Switch to {styleMode === "light" ? "dark" : "light"} mode
</button>
*/
