import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import HomePage from "./components/HomePage";
//import ProjectsPage from "./components/ProjectsPage";
//import AboutPage from "./components/AboutPage";
//import ContactPage from "./components/ContactPage";

export default function App() {
  const [styleMode, setStyleMode] = useState(
    localStorage.getItem("styleMode") || "light"
  );

  useEffect(() => {
    document.body.classList.toggle("dark-mode", styleMode === "dark");
  }, [styleMode]);

  function switchStyleMode() {
    const newStyleMode = styleMode === "light" ? "dark" : "light";
    setStyleMode(newStyleMode);
    localStorage.setItem("styleMode", newStyleMode);
    //window.location.reload();
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <HomePage styleMode={styleMode} setStyleMode={switchStyleMode} />
            }
          />
        </Routes>
      </Router>
    </>
  );
}
