import React from "react";

export default function Sitemap({
  toggleHome,
  toggleProjects,
  toggleContact,
  toggleAbout,
}) {
  return (
    <div className="sitemap">
      <span className="label">sitemap</span>
      <p className="buttons">
        <span className="link" onClick={toggleHome}>
          home
        </span>
        <br />
        <span className="link" onClick={toggleProjects}>
          projects
        </span>
        <br />
        <span className="link" onClick={toggleContact}>
          contact
        </span>
        <br />
        <span className="link" onClick={toggleAbout}>
          about
        </span>
      </p>
    </div>
  );
}
