import React, { useRef, useEffect } from "react";
import anime from "animejs";

const OpacityLoop = ({ children }) => {
  const spanRef = useRef(null);

  useEffect(() => {
    const spanElement = spanRef.current;

    const animation = anime({
      targets: spanElement,
      keyframes: [{ opacity: 0.5 }, { opacity: 1 }],
      duration: 4000,
      delay: anime.stagger(200, { direction: "normal" }),
      easing: "linear",
      loop: true,
    });

    return () => {
      animation.pause(); //pause upon component unmount
    };
  }, []);

  return <span ref={spanRef}>{children}</span>;
};

export default OpacityLoop;
