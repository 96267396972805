// https://www.npmjs.com/package/react-responsive-carousel

import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function DemoCarousel() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch("/api/images/carousel")
      .then((response) => response.json())
      .then((data) => {
        setImages(data.images);
        console.log(data.images);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  const onChange = () => {
    console.log("Changed");
  };

  const onClickItem = () => {
    console.log("Item clicked");
  };

  const onClickThumb = () => {
    console.log("Thumbnail clicked");
  };
  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      onChange={onChange}
      onClickItem={onClickItem}
      onClickThumb={onClickThumb}
    >
      {images.map((image, index) => (
        <div key={index}>
          <img src={image.url} alt={image.caption} />
          <p className="legend">
            Legend {image.id}
            <br />
          </p>
        </div>
      ))}
    </Carousel>
  );
}
