import React from "react";

export default function ContactPageAlt() {
  return (
    <div className="bottom-text">
      <Discord />
      <Github />
      <LinkedIn />
    </div>
  );
}

function Discord() {
  return (
    <div>
      <span className="c-text" style={{ paddingBottom: ".4vh" }}>
        2goblin
      </span>
      <svg
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        width="60"
        height="60"
        className="c-logo"
        style={{}}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <defs>
            <style>
              {`.c{fill:none;stroke:#000000;stroke-linecap:round;stroke-linejoin:round;}`}
            </style>
          </defs>
          <path
            className="c"
            d="m17.59,34.1733c-.89,1.3069-1.8944,2.6152-2.91,3.8267-7.38-.21-10.18-5-10.18-5,.1049-6.7869,1.7496-13.4614,4.81-19.52,2.6902-2.1046,5.9681-3.3207,9.38-3.48l1,2.31c1.4283-.1983,2.8681-.3018,4.31-.31,1.4484.0048,2.8948.105,4.33.3l1-2.31c3.4152.1679,6.6935,1.3947,9.38,3.51,3.0506,6.0542,4.6883,12.7214,4.79,19.5,0,0-2.8,4.79-10.18,5-1.0166-1.2213-1.9711-2.4929-2.86-3.81m6.46-2.9c-3.84,1.9454-7.5555,3.89-12.92,3.89s-9.08-1.9446-12.92-3.89"
          ></path>
          <path
            className="c"
            d="m21.197,26.23c0,1.8502-1.4998,3.35-3.35,3.35s-3.35-1.4998-3.35-3.35h6.7Z"
          ></path>
          <path
            className="c"
            d="m33.503,26.23c0,1.8502-1.4998,3.35-3.35,3.35s-3.35-1.4998-3.35-3.35h6.7Z"
          ></path>
        </g>
      </svg>
      <br />
    </div>
  );
}

function Github() {
  return (
    <div>
      <span className="c-text">
        check out my repositories ⇢
        <a href="https://github.com/ethanperalta" className="c-link">
          /ethanperalta
        </a>
      </span>

      <svg
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#000000"
        height="62px"
        width="62px"
        className="c-logo"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M20 56a4 4 0 0 0 4-4 8 8 0 0 1 2.35-5.67s-13.69-1.53-16.6-7.41A21.67 21.67 0 0 1 8 29.42 17.23 17.23 0 0 1 11.9 18a15.43 15.43 0 0 1-.77-4.82C11.13 11 11 10 12 8c4.48 0 8 .85 11.37 3.52A39.58 39.58 0 0 1 32 10.7a39.58 39.58 0 0 1 8.63.82C44 8.85 47.52 8 52 8c1 1.95.87 3 .87 5.18A15.43 15.43 0 0 1 52.1 18 17.23 17.23 0 0 1 56 29.42a21.67 21.67 0 0 1-1.75 9.5c-2.91 5.88-16.6 7.41-16.6 7.41A8 8 0 0 1 40 52a4 4 0 0 0 4 4"></path>
          <path d="M24 50.88c-4 2-6.31-.43-8.75-2S11 46.75 8 48"></path>
          <ellipse cx="22.81" cy="31.72" rx="3.19" ry="4.82"></ellipse>
          <ellipse cx="41.19" cy="31.72" rx="3.19" ry="4.82"></ellipse>
        </g>
      </svg>

      <br />
    </div>
  );
}

function LinkedIn() {
  return (
    <div>
      <span className="c-text">
        connect with me ⇢
        <a
          href="https://www.linkedin.com/in/ethan-peralta-3a209221b"
          className="c-link"
        >
          /ethan-peralta-3a209221b
        </a>
      </span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height="53px"
        width="53px"
        className="c-logo"
      >
        <path
          fill="black"
          d="M7.5 9h-4a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5M7 21H4V10h3zM18 9c-1.085 0-2.14.358-3 1.019V9.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V16a1.5 1.5 0 1 1 3 0v5.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V14a5.006 5.006 0 0 0-5-5m4 12h-3v-5a2.5 2.5 0 1 0-5 0v5h-3V10h3v1.203a.5.5 0 0 0 .89.313A3.983 3.983 0 0 1 22 14zM5.868 2.002A2.73 2.73 0 0 0 5.515 2a2.74 2.74 0 0 0-2.926 2.729a2.71 2.71 0 0 0 2.869 2.728h.028a2.734 2.734 0 1 0 .382-5.455M5.833 6.46a1.75 1.75 0 0 1-.347-.003h-.028A1.736 1.736 0 1 1 5.515 3a1.737 1.737 0 0 1 .318 3.46"
        ></path>
      </svg>
    </div>
  );
}
