import React, { useEffect, useRef } from "react";
import anime from "animejs";

const WormLetters = ({ text }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const currentRef = containerRef.current; // containerRef.current cleanup fix
    if (!currentRef) return;
    const animateLetters = () => {
      const letters = containerRef.current.querySelectorAll(".letter");

      anime
        .timeline({ loop: true })
        .add({
          targets: letters,
          translateY: [-20, 0], // Move down
          opacity: [0, 1],
          easing: "easeOutQuad",
          duration: 2000,
          delay: anime.stagger(35), // Add delay between each letter
          endDelay: 3000,
        })
        .add({
          targets: letters,
          translateY: 30, // Move down again
          opacity: [1, 0],
          easing: "easeOutQuad",
          duration: 3000,
          delay: anime.stagger(25), // Add delay between each letter
          endDelay: 3000,
        });
    };
    animateLetters();
    return () => {
      if (currentRef) anime.remove(currentRef);
    };
  }, [text]);

  return (
    <p
      ref={containerRef}
      style={{
        position: "absolute",
        top: "94vh",
        left: "2.5vw",
        display: "inline-block",
        whiteSpace: "pre",
        height: "10vh",
        overflow: "hidden",
      }}
    >
      {text.split("").map((letter, index) => (
        <span
          key={index}
          className="letter"
          style={{ display: "inline-block" }}
        >
          {letter}
        </span>
      ))}
    </p>
  );
};

export default WormLetters;
