import React, { useState, useRef, useEffect } from "react";
import anime from "animejs";
import OpacityLoop from "./OpacityLoop";
import WormLetters from "./WormLetters";
//import Sitemap from "./Sitemap";
import Sitemap from "./Sitemap";
import ContactPageAlt from "./ContactPageAlt";
import AboutPageAlt from "./AboutPageAlt";
import DemoCarousel from "./DemoCarousel";
import ToggleDarkMode from "./ToggleDarkMode";
//import ToggleButtonAlternate from "./ToggleButtonAlternate";
//import CenterMode from "./CenterMode";

export default function HomePage({ styleMode, setStyleMode }) {
  const [showHome, setShowHome] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const projectsRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    if (showProjects) {
      anime.remove(projectsRef.current);
      anime({
        targets: projectsRef.current,
        opacity: [projectsRef.current.style.opacity || 0, 1],
        duration: 1000,
        easing: "easeInOutQuad",
        begin: () => {
          projectsRef.current.style.display = "block";
        },
      });
    } else {
      anime.remove(projectsRef.current);
      anime({
        targets: projectsRef.current,
        opacity: [projectsRef.current.style.opacity || 1, 0],
        duration: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          projectsRef.current.style.display = "none";
        },
      });
    }

    if (showAbout) {
      anime.remove(aboutRef.current);
      anime({
        targets: aboutRef.current,
        opacity: [aboutRef.current.style.opacity || 0, 1],
        duration: 1000,
        easing: "easeInOutQuad",
        begin: () => {
          aboutRef.current.style.display = "block";
        },
      });
    } else {
      anime.remove(aboutRef.current);
      anime({
        targets: aboutRef.current,
        opacity: [aboutRef.current.style.opacity || 1, 0],
        duration: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          aboutRef.current.style.display = "none";
        },
      });
    }

    if (showContact) {
      anime.remove(contactRef.current);
      anime({
        targets: contactRef.current,
        opacity: [contactRef.current.style.opacity || 0, 1],
        duration: 1000,
        easing: "easeInOutQuad",
        begin: () => {
          contactRef.current.style.display = "block";
        },
      });
    } else {
      anime.remove(contactRef.current);
      anime({
        targets: contactRef.current,
        opacity: [contactRef.current.style.opacity || 1, 0],
        duration: 1000,
        easing: "easeInOutQuad",
        complete: () => {
          contactRef.current.style.display = "none";
        },
      });
    }
  }, [showProjects, showAbout, showContact]);

  return (
    <div className="homepage">
      <div className="inset-page">
        <ToggleDarkMode styleMode={styleMode} setStyleMode={setStyleMode} />
        <div className="triple-column-container">
          <div className="triple-column-column">
            <Titles />
            <div ref={aboutRef} style={{ opacity: 0, display: "none" }}>
              <About />
            </div>
            <Sitemap
              toggleHome={() => setShowHome(!showHome)}
              toggleProjects={() => setShowProjects(!showProjects)}
              toggleContact={() => setShowContact(!showContact)}
              toggleAbout={() => setShowAbout(!showAbout)}
            />
          </div>

          <div className="triple-column-column">
            <div ref={projectsRef} style={{ opacity: 0, display: "none" }}>
              <DemoCarousel />
            </div>
          </div>

          <div className="triple-column-column">
            <div ref={contactRef} style={{ opacity: 0, display: "none" }}>
              <Contact />
            </div>
          </div>
        </div>
        <span>
          <WormLetters text="code & design © ethan peralta 2024" />
        </span>
      </div>
    </div>
  );
}

function Titles() {
  return (
    <div>
      ethan peralta
      <br />
      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
        <span>
          <OpacityLoop>developer</OpacityLoop>,{" "}
        </span>
        <span>
          <OpacityLoop>editor</OpacityLoop>,{" "}
        </span>
        <span>
          <OpacityLoop>designer</OpacityLoop>
        </span>
      </p>
    </div>
  );
}

function About() {
  return (
    <div>
      <AboutPageAlt />
    </div>
  );
}

function Contact() {
  return (
    <div>
      <ContactPageAlt />
    </div>
  );
}
